import { Highlight } from '@capturi/ui-components'
import { Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdCheck, MdClose } from 'react-icons/md'

import { ScoreParameterResult } from '../useBreakdown'

export const ScoreParameterItem: React.FC<{
  param: ScoreParameterResult
  success?: boolean
}> = ({ param, success }) => {
  return (
    <Flex
      align="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      p={2}
      css={{ direction: 'ltr' }}
      minW={0}
    >
      <Icon as={param.filter.icon} mr={1} />
      <Text fontSize="sm" noOfLines={1} wordBreak="break-all">
        <span>{param.filter.label}</span>
      </Text>
      {success !== undefined && (
        <Icon
          as={success ? MdCheck : MdClose}
          color={success ? 'success' : 'danger'}
          ml={2}
        />
      )}
      <Highlight ml={1}>+{param.parameter.weight}</Highlight>
    </Flex>
  )
}
