import {
  ConversationResponseModel,
  useConversation,
} from '@capturi/api-conversations'
import { useScores } from '@capturi/api-scoring'
import { useCurrentUser } from '@capturi/core'
import { ScoreBadge } from '@capturi/scoring'
import {
  ContentPlaceholder,
  Description,
  Emoji,
  Highlight,
  SectionHeading,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Grid,
  Icon,
  IconProps,
  List,
  ListIcon,
  ListItem,
  Stack,
  StackDivider,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ButtonLink } from 'components/Link'
import ProcessingTag from 'components/ProcessingTag'
import { routes as scoreEditorRoutes } from 'pages/ScoringPage/routes'
import React from 'react'
import { MdAdd, MdThumbDown, MdThumbUp } from 'react-icons/md'
import { useEffectOnce } from 'react-use'

import { Hit } from '../Audio/types'
import {
  ScoreWithConversationData,
  useScoresWithConversationData,
} from '../Hooks/useScoresWithConversationData'
import { ScoreParameterItem } from './components/ScoreParameterItem'
import { StackedBar } from './components/StackedBar'
import { useBreakdown } from './useBreakdown'

type ScoringTabContentProps = {
  results: ConversationResponseModel['scores']
  setHits: (hits: Hit[]) => void
  conversationUid: string
}
export const ScoringTabContent: React.FC<ScoringTabContentProps> = ({
  results,
  setHits,
  conversationUid,
}) => {
  const user = useCurrentUser()
  useEffectOnce(() => {
    setHits?.([])
  })

  const [shouldDirtyPoll, setShouldDirtyPoll] = React.useState(false)
  const { data: scores } = useScores(shouldDirtyPoll ? 7000 : 0)
  const { refetch: refetchConversation } = useConversation(
    conversationUid,
    shouldDirtyPoll ? 7000 : 0,
  )

  React.useEffect(() => {
    if (!scores) return

    const isProcessing = scores.some((x) => x.isProcessing === true)
    if (isProcessing) {
      if (!shouldDirtyPoll) {
        setShouldDirtyPoll(true)
      }
    } else {
      if (shouldDirtyPoll) {
        setShouldDirtyPoll(false)
        refetchConversation()
      }
    }
  }, [scores, shouldDirtyPoll, refetchConversation])
  const scoresWithConversationData = useScoresWithConversationData(
    scores,
    Object.values(results),
  )

  if (scoresWithConversationData?.length === 0) {
    return (
      <ContentPlaceholder.Container mt={8} size="md">
        <ContentPlaceholder.Heading>
          <Trans>No scoring found</Trans>
          <Emoji symbol="🎯" fontSize="4xl" pl={2} />
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>No score on this conversation</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          {user.isAdminOrTeamLead && (
            <ButtonLink
              to={scoreEditorRoutes.createScore()}
              leftIcon={<MdAdd />}
              primary
            >
              <Trans>Create score</Trans>
            </ButtonLink>
          )}
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }
  return (
    <Box>
      <Stack spacing={8} shouldWrapChildren divider={<StackDivider />}>
        {scoresWithConversationData?.length &&
          scoresWithConversationData.map((score) => (
            <ScoreBreakdown
              key={score.uid}
              score={score}
              conversationUid={conversationUid}
            />
          ))}
      </Stack>
    </Box>
  )
}

const ScoreBreakdown: React.FC<{
  score: ScoreWithConversationData
  conversationUid: string
}> = ({ score }) => {
  const breakdown = useBreakdown(score)

  if (!(score && breakdown)) return null

  const { hits, misses } = breakdown
  return (
    <Box>
      <Flex align="center" mb={1}>
        <SectionHeading size="xl" textTransform="capitalize" mb={0}>
          {score.name}
        </SectionHeading>
        {score.isProcessing ? (
          <ProcessingTag ml={4} />
        ) : (
          <ScoreBadge
            score={score.conversationData.score}
            maxScore={score.maxPossibleScore}
            goal={score.goal}
            ml={4}
          />
        )}
      </Flex>
      {score.description && (
        <Description fontSize="sm">{score.description}</Description>
      )}
      <Box mt={4}>
        <StackedBar
          hits={hits}
          misses={misses}
          maxScore={score.maxPossibleScore}
          goal={score.goal}
        />
      </Box>
      <Grid templateColumns={{ md: '1fr 1fr' }} gap={4} mt={8}>
        <Box minW={0} overflow="hidden">
          <Highlight display="flex" alignItems="center">
            <Icon as={MdThumbUp} mr={1} />
            <Trans>Occurs in conversation</Trans>
          </Highlight>
          <List spacing={2} mt={2}>
            {hits.map((x) => (
              <ListItem
                key={x.parameter.uid}
                display="flex"
                alignItems="center"
              >
                <ListBullet bg={x.color} />
                <ScoreParameterItem param={x} success />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box minW={0} overflow="hidden">
          <Highlight
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Icon as={MdThumbDown} mr={1} />
            <Trans>Does NOT occur in conversation</Trans>
          </Highlight>
          <List spacing={2} mt={2}>
            {misses.map((x) => (
              <ListItem
                key={x.parameter.uid}
                display="flex"
                alignItems="center"
                css={{ direction: 'rtl' }}
              >
                <ListBullet
                  bg={x.color}
                  border="1px"
                  borderStyle="dashed"
                  borderColor="gray.500"
                />
                <ScoreParameterItem param={x} success={false} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Box>
  )
}

const ListBullet: React.FC<IconProps> = (props) => (
  <ListIcon
    as={Box}
    bg="gray.400"
    display="inline-block"
    boxSize={2}
    borderRadius="2px"
    {...props}
  />
)
